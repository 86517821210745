import { graphql } from "gatsby";
import React, { useContext, useEffect } from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { toPlainText, toTextBlock } from "../lib/helpers";
import {
  GetStarted,
  HubspotWebform,
  PageHeader,
  SectionWrapper,
} from "../components/sections";
import PortableText from "../components/portableText";
import { Button } from "../components/button";
import { SiteContext } from "../components/global/site-context";
import { BackToZone } from "../components/back-to-zone";
import { CardGrid } from "../components/card/card-grid";
import { MAX_SUGGESTED_POSTS, suggest } from "../lib/suggestions";
import { TableOfContentsSection } from "../components/playbook-page";
import { ArrowLeft } from "../components/icon/arrow-left";
import { ImageBuilder } from "../components/global/image-builder";

const TEASER_IMAGE_HEIGHT = 170;

export const query = graphql`
  query AssetTemplateQuery($id: String!) {
    doc: sanityAsset(id: { eq: $id }) {
      _type
      _id
      status
      title
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      mainImage {
        ...SanityImage
      }
      _rawBody(resolveReferences: { maxDepth: 12 })
      file {
        asset {
          url
        }
      }
      externalUrl
      formId
      categories {
        _id
      }
      relatedPosts {
        ...cardNode
      }
      relatedAssets {
        ...cardNodeAsset
      }
      relatedPlaybooks {
        ...cardNodePlaybook
      }
      slug {
        current
      }
      graphic {
        ...SanityImageSimple
      }
    }
    recentPosts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: [DESC] }
      filter: { slug: { current: { ne: null } }, isPublished: { eq: true } }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
  }
`;

const AssetTemplate = ({ data }) => (
  <LayoutContainer doc={data.doc}>
    <PageHeadMeta
      title={data.doc.title}
      description={toPlainText(data.doc._rawExcerpt)}
      image={data.doc.mainImage}
      doc={data.doc}
    />
    {data.doc && <AssetPage {...data.doc} suggest={suggest(data)} />}
  </LayoutContainer>
);

export default AssetTemplate;

const AssetPage = (props) => {
  const {
    title,
    file,
    externalUrl,
    formId,
    suggest,
    slug,
    _rawBody,
    graphic,
    _rawExcerpt,
  } = props;
  const site = useContext(SiteContext);
  const assetUrl = file?.asset?.url || externalUrl;

  useEffect(() => {
    if (!formId && !assetUrl) return;

    site.metrics.analytics.once("ready", () => {
      site.metrics.logEvent({
        category: "passive",
        action: formId
          ? "asset-form-view"
          : assetUrl
          ? "asset-download-view"
          : null,
        label: slug.current,
      });
    });
  }, []);

  return (
    <>
      {assetUrl ? (
        <SectionWrapper>
          {site.isActive("resourceHub") && (
            <BackToZone to="/resources/" text={site.token("backToResources")} />
          )}
          <DownloadableAsset {...{ ...props, assetUrl }} />
        </SectionWrapper>
      ) : (
        <>
          <SectionWrapper
            {...{
              noHeaders: true,
              background: "dots-robin",
              name: "header",
              backgroundCentered: true,
              mainImage: graphic,
              layout: "mediaCentered",
            }}
          >
            <div className="text-secodary pb-8">
              <Button
                text="See all resources"
                href="/resources/"
                icon={<ArrowLeft size="12" />}
                bgColor="bg-dark-10"
              />
            </div>
            <PageHeader
              section={{
                _rawSummary: toTextBlock(title, "h2").concat(
                  _rawExcerpt || toTextBlock("Missing excerpt", "p")
                ),
                mainImage: graphic,
              }}
            />
          </SectionWrapper>
          <SectionWrapper padding="tight" constrainImages={true} rounded={true}>
            <TableOfContentsSection {...{ _rawBody }} />
          </SectionWrapper>
        </>
      )}

      {site.isActive("suggestions") && (
        <SectionWrapper
          header={site.token("resourcesSuggestions")}
          padding="top"
          anchor="more"
          container="thin"
        >
          <CardGrid
            nodes={suggest.slice(0, MAX_SUGGESTED_POSTS)}
            showSubTags={false}
            showExtended={false}
            browseMoreHref="/resources/"
            browseMoreText="See all resources"
            hideTitles={true}
            maxColumns={2}
            showSummary={true}
            panels={true}
            attribution={{ action: "suggestion" }}
          />
        </SectionWrapper>
      )}
      <SectionWrapper container="large">
        <GetStarted />
      </SectionWrapper>
    </>
  );
};

const DownloadableAsset = ({
  title,
  _rawBody,
  mainImage,
  formId,
  slug,
  assetUrl,
}) => (
  <div className="grid grid-cols-1 md:grid-cols-2/3-1/3 gap-0 md:gap-12">
    <div>
      <h1 className="text-2xl md:text-5xl font-bold tracking-tight mb-4">
        {title}
      </h1>

      {mainImage && (
        <ImageBuilder
          image={mainImage}
          height={TEASER_IMAGE_HEIGHT}
          alt={title}
          className="float-left mr-6 mb-4"
        />
      )}
      <PortableText blocks={_rawBody} className="mb-12" />
    </div>
    <div className="mt-20">
      {formId ? (
        <HubspotWebform section={{ formId }} />
      ) : (
        assetUrl && (
          <div className="p-4 py-12 bg-dark-5 rounded-xl flex justify-center items-center">
            <Button
              text="Download Now"
              bgColor="bg-black"
              href={assetUrl}
              metrics={{
                action: "asset-download",
                label: slug.current,
              }}
              attributes={{ target: "_blank" }}
            />
          </div>
        )
      )}
    </div>
  </div>
);
